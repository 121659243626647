<template>
  <div>
    <CmsSite />
  </div>
</template>

<script>
export default {
  components: {
    CmsSite: () => import('./components/site/index.vue'),
  },
}
</script>
